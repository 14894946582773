
export class Comment {

  premeraId?: string = null;
  comment: string;
  memberSubmittedName: string;
  memberSubmittedContactInfo: string;
  surveyType = 'ListeningPost';
  businessUnit: string;
  surveyCompletedDate: Date = new Date();
  redirectSource: string;
  appName: string;
  device: string;
  operatingSystem: string;
  appVersion: string;
  isHmo: boolean;
  isAlaska: boolean;
  isMedicareAdvantage: boolean;
  providerOrganization: string;
  providerSubmittedContactInfo: string;
  providerSubmittedName: string;
  providerTin: string;

}

export class FooterSettings {

  legalDisclaimerHtml: string;
  legalEntityName: string;
  pdfUrl: string;
  languages: SupportedLanguage[]

}

export class SupportedLanguage {

  nativeName: string;
  name: string;
  languageCode: string;

}
