import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Constants } from "./shared/constants";
import { ListeningPostSettings } from './listening-post-settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private doc, @Inject('Window') private window: Window) {}

  cssUrl: string;
  favIconUrl: string;

  ngOnInit() {
    ListeningPostSettings.lineOfBusiness = this.setLineOfBusiness();
    ListeningPostSettings.brandingType = this.setBrandingType();
    ListeningPostSettings.logoImagePath = this.setLogoImagePath();
    ListeningPostSettings.surveyType = this.surveyType();

    this.doc.title = this.isLifeWise() ? Constants.lifeWiseListensTitle : Constants.premeraListensTitle;
    this.setFavIcon();
    this.setStyleSheetLink();
  }

  private setLineOfBusiness(): string {
    return this.isLifeWise()
      ? Constants.lineOfBusinessLifeWise
      : Constants.lineOfBusinessPremera;
  }

  private setBrandingType(): string {
    if (this.isLifeWise() && this.isStudentInsurance()) return Constants.brandTypeLifeWiseAssuranceCompany;
    if (this.isLifeWise()) return Constants.brandTypeLifeWiseOfWashington;
    if (this.isHmo()) return Constants.brandTypePremeraHMO;
    if (this.isAlaska()) return Constants.brandTypePremeraAlaska;
    if (this.isMedicareAdvantage()) return Constants.brandTypePremeraMedicareAdvantage;
    return Constants.brandTypePremeraBlueCross;
  }

  private setLogoImagePath(): string {
    if (this.isLifeWise() && this.isStudentInsurance()) return Constants.lwacLogoImagePath;
    if (this.isLifeWise()) return Constants.lifeWiseLogoImagePath;
    if (this.isHmo()) return Constants.premeraHmoLogoImagePath;
    if (this.isAlaska()) return Constants.premeraBlueCrossAlaskaLogoImagePath;
    return Constants.premeraLogoImagePath;
  }

  private surveyType(): string {
    if (this.isMobile()) return Constants.surveyTypeListeningPostMobile;
    if (this.isHmo()) return Constants.surveyTypeListeningPostHmo;
    if (this.isMedicareAdvantage()) return Constants.surveyTypeListeningPostMedicareAdvantage;
    if (this.isMemberCommunications()) return Constants.surveyTypeListeningPostMemberCommunications;
    if (this.isProvider()) {
      if (this.isStudentInsurance()) return Constants.surveyTypeListeningPostProviderStudent;
      if (this.isLifeWiseGroup()) return Constants.surveyTypeListeningPostProviderGroup;
      return Constants.surveyTypeListeningPostProvider;
    }
    if (this.isLifeWise()) {
      if (this.isStudentInsurance()) return Constants.surveyTypeListeningPostStudentInsurance;
      if (this.isLifeWiseGroup()) return Constants.surveyTypeListeningPostLifewiseGroup;
      return Constants.surveyTypeListeningPostIndividual;
    }
    return Constants.surveyTypeListeningPost;
  }

  private isHmo(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeHmo.toLowerCase()) !== -1;
  }

  private isAlaska(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeAlaska.toLowerCase()) !== -1;
  }

  private isLifeWise(): boolean {
    return this.window.location.hostname.toLowerCase().indexOf('lifewise') !== -1;
  }

  private isMedicareAdvantage(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeMedicareAdvantage.toLowerCase()) !== -1;
  }

  private isMemberCommunications(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeMemberCommunications.toLowerCase()) !== -1;
  }

  private isStudentInsurance(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeStudentInsurance.toLowerCase()) !== -1;
  }

  private isProvider(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeProvider.toLowerCase()) !== -1;
  }

  private isLifeWiseGroup(): boolean {
    return this.window.location.pathname.toLowerCase().indexOf(Constants.routeLifewiseGroup.toLowerCase()) !== -1;
  }

  private isMobile(): boolean {
    const params = new URLSearchParams(this.window.location.search);
    const redirect = params.get("redirect");
    return redirect && redirect.toUpperCase() === "MOBILE";
  }

  private setStyleSheetLink(): void {
    this.cssUrl = this.cssUri();
    const cssLink: HTMLLinkElement = this.doc.createElement('link');
    cssLink.setAttribute('rel', 'stylesheet');
    cssLink.setAttribute('href', this.cssUrl);
    this.doc.head.appendChild(cssLink);
  }

  private setFavIcon(): void {
    this.favIconUrl = this.isLifeWise() ? Constants.lifeWiseFavIconUri : Constants.premeraFavIconUri;
    const iconLink: HTMLLinkElement = this.doc.createElement('link');
    iconLink.setAttribute('rel', 'icon');
    iconLink.setAttribute('type', 'image/x-icon');
    iconLink.setAttribute('href', this.favIconUrl);
    this.doc.head.appendChild(iconLink);
  }

  private cssUri(): string {
    return this.isLifeWise() ? Constants.lifeWiseCssUri : Constants.premeraCssUri;
  }

}
