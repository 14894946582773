<header id ="header" class="main-loading main-in">
  <nav aria-labelledby="nav1" offscrollclass="off" onscrollclass="on" pbcaddclassonscroll="" aria-hidden="false" class="on">
      <div id="nav1">
      </div>
  </nav>
  <nav aria-labelledby="nav2" class="navbar navbar-expand-md">
      <div class="logo" id="nav2" [ngClass]="{'lifewise': lob == 'LifeWise'}">
        <a class="navbar-brand"
           data-analytics-override-route="header"
           data-analytics-title="header logo"
           pbcanalyticstag=""
           href="{{homeUrl}}"
           data-analytics-tag="header_header_logo_listeningpost">
          <img alt="{{lob}}" aria-hidden="true" aria-label="Listening Post" class="img-fluid" src="{{logo}}">
          <span class="sr-only">{{lob}} home</span>
        </a>
      </div>
  </nav>
</header>
