export class Constants {

  static lineOfBusinessPremera = "Premera";
  static premeraCssUri = "/assets/pbc.css";
  static premeraFavIconUri = "/assets/images/pbc_favicon.ico";
  static premeraLogoImagePath = "/assets/images/pbc_logo.svg";
  static premeraHmoLogoImagePath = "/assets/images/PBCHMO_Blue_stack.png";
  static premeraBlueCrossAlaskaLogoImagePath = "/assets/images/pbc_logo_AK.svg";
  static premeraListensTitle = "PremeraLISTENS";

  static lineOfBusinessLifeWise = "LifeWise";
  static lifeWiseCssUri = "/assets/lifewise.css";
  static lifeWiseFavIconUri = "/assets/images/lw_favicon.ico";
  static lifeWiseLogoImagePath = "/assets/images/LifeWise_Logo_Color.svg";
  static lwacLogoImagePath = "/assets/images/LWAC_logo.png";
  static lifeWiseListensTitle = "LifeWise Listens";

  static routeHome = "Home";
  static routeAlaska = "Alaska";
  static routeHmo = "Hmo";
  static routeMedicareAdvantage = "MedicareAdvantage";
  static routeMemberCommunications = "MemberCommunications";
  static routeThankYou = "ThankYou";
  static routeThankYouHmo = "ThankYouHmo";
  static routeProvider = "Provider";
  static routeInvalidRoute = "InvalidRoute";
  static routeStudentInsurance = "StudentInsurance";
  static routeLifewiseGroup = "Group";
  static routeProviderStudentInsurance = "Provider/StudentInsurance";
  static routeProviderLifewiseGroup = "Provider/Group";

  static surveyTypeListeningPost = "ListeningPost";
  static surveyTypeListeningPostMedicareAdvantage = "ListeningPost-MA";
  static surveyTypeListeningPostHmo = "ListeningPost-HMO";
  static surveyTypeListeningPostMemberCommunications = "ListeningPost-MemberComms";
  static surveyTypeListeningPostIndividual = "ListeningPost-Individual";
  static surveyTypeListeningPostMobile = "ListeningPost-Mobile";
  static surveyTypeListeningPostProvider = "ListeningPost-Provider";
  static surveyTypeListeningPostStudentInsurance = "ListeningPost-LWAC-Student";
  static surveyTypeListeningPostLifewiseGroup = "ListeningPost-LWWACC-Group";
  static surveyTypeListeningPostProviderStudent = "ListeningPost-Provider-LWAC-Student";
  static surveyTypeListeningPostProviderGroup = "ListeningPost-Provider-LWWACC-Group";

  static brandTypeLifeWiseAssuranceCompany = "LifeWiseAssuranceCompany";
  static brandTypeLifeWiseOfWashington = "LifeWiseOfWashington";
  static brandTypePremeraBlueCross = "PremeraBlueCross";
  static brandTypePremeraHMO = "PremeraHMO";
  static brandTypePremeraAlaska = "PremeraAlaska";
  static brandTypePremeraMedicareAdvantage = "PremeraMedicareAdvantage";

}
