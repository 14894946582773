import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Constants } from '../shared/constants';
import { ListeningPostSettings } from '../listening-post-settings';

@Component({
  selector: 'pl-thankYou',
  templateUrl: './thankYou.component.html',
  styleUrls: ['./thankYou.component.scss']
})
export class ThankYouComponent implements OnInit {

  title: string;

  constructor(location: PlatformLocation) {
    this.disableBack(location);
  }

  disableBack(location: PlatformLocation) {
    history.pushState(null, null, window.location.href);
    location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() {
    this.title = ListeningPostSettings.lineOfBusiness === Constants.lineOfBusinessLifeWise
      ? Constants.lifeWiseListensTitle
      : Constants.premeraListensTitle;
  }

}
