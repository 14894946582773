import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Comment } from '../shared/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class FeedbackService {

  data = [];
  detail = [];
  private headers = { headers: { 'Content-Type': 'application/json' }};

  constructor(private http: HttpClient) { }

  addComment(comment: Comment): Observable<object> {
    return this.http.post(environment.services, JSON.stringify(comment), this.headers);
  }

}
