<pl-header></pl-header>
<main>
  <div id="mainContent" role="main" tabindex="-1">
    <div class="row">
      <div class="col-sm-8 mx-auto">
        <h1>{{title}}</h1>
        <h2>This is really important feedback. We'll share your comments with our entire team right away. Thank you for your time.</h2>
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
  <pl-footer></pl-footer>
</main>
