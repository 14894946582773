import { FooterSettings, SupportedLanguage } from "../../shared/models";
import { ListeningPostFooterContentBase } from "./listening-post-footer-content-base";

export class PremeraBlueCrossAlaskaFooterContent extends ListeningPostFooterContentBase {

  //PBC Alaska
  private documentLink = "https://www.premera.com/documents/037398.pdf";
  private entityName = "Premera Blue Cross Blue Shield of Alaska";
  private federal = "Federal civil rights laws";

  pdfUrl(): string {
    return this.documentLink;
  }

  legalEntityName(): string {
    return this.entityName;
  }

  federalLawsBit(): string {
    return this.federal;
  }

  languageSupport(): SupportedLanguage[] {
    return [
      this.tagalog(),
      this.spanish(),
      this.korean(),
      this.hmong(),
      this.russian(),
      this.chinese(),
      this.samoan(),
      this.lao(),
      this.japanese(),
      this.iloko(),
      this.vietnamese(),
      this.ukrainian(),
      this.thai(),
      this.german(),
      this.polish(),
      this.arabic(),
      this.haitian(),
      this.french(),
      this.portuguese(),
      this.italian(),
      this.farsi()
    ]
  }
}
